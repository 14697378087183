import React from "react";
import Image from "next/image";
import Link from "next/link";
import {
  Facebook as FacebookLogo,
  Instagram as InstagramLogo,
  ExternalLink as ExternalLinkIcon,
} from "./icons";
import { Lang } from "../types";

function Footer({ lang }: { lang: Lang }): JSX.Element {
  return (
    <footer>
      <section className="main-footer">
        <div className="column-container">
          <Image
            src="/spvmLogo.svg"
            alt="Spårvägsmuseet Logo"
            width="200"
            height="95"
            unoptimized
          />
        </div>
        <div className="column-container">
          <h3>{lang.footer.contact}</h3>
          <p>Gasverkstorget 1</p>
          <p>115 42 Stockholm</p>
          <br />
          <br />
          <p>08-686 17 60</p>
          <a href="mailto:sparvagsmuseet@sl.se">sparvagsmuseet@sl.se</a>
        </div>
        <div className="column-container">
          <div className="socials-wrapper">
            <h3>{lang.footer.accessibility.title}</h3>
            <Link href="/tillganglighet">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>{lang.footer.accessibility.link}</a>
            </Link>
            <br />
            <br />
            <h3>{lang.footer.follow}</h3>
            <div className="socials">
              <a
                href="https://www.instagram.com/sparvagsmuseet/"
                aria-label="Instagram"
              >
                <InstagramLogo />
              </a>
              <a
                href="https://sv-se.facebook.com/Sp%C3%A5rv%C3%A4gsmuseet-270038314014/"
                aria-label="Facebook"
              >
                <FacebookLogo />
              </a>
            </div>
          </div>
        </div>
        <div className="column-container">
          <h3>{lang.footer.externalLinks.title}</h3>
          <a href="https://sl.se/" rel="noreferrer">
            <div className="external-link">
              {lang.footer.externalLinks.links[0]}
              <ExternalLinkIcon />
            </div>
          </a>
          <a href="https://fardtjansten.regionstockholm.se/" rel="noreferrer">
            <div className="external-link">
              {lang.footer.externalLinks.links[1]}
              <ExternalLinkIcon />
            </div>
          </a>
          <a href="https://waxholmsbolaget.se/" rel="noreferrer">
            <div className="external-link">
              {lang.footer.externalLinks.links[2]}
              <ExternalLinkIcon />
            </div>
          </a>
          <a href="https://konst.sl.se/" rel="noreferrer">
            <div className="external-link">
              {lang.footer.externalLinks.links[3]}
              <ExternalLinkIcon />
            </div>
          </a>
          <a href="https://www.sll.se/" rel="noreferrer">
            <div className="external-link">
              {lang.footer.externalLinks.links[4]}
              <ExternalLinkIcon />
            </div>
          </a>
        </div>
      </section>
      <section className="footer-bottom">
        <h2 style={{ display: "none" }}>{lang.footer.bottom.title}</h2>
        <p className="small-text">© 2021 Spårvägsmuseet</p>
      </section>
    </footer>
  );
}

export default Footer;
