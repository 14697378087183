/* eslint-disable @next/next/no-html-link-for-pages */

import React, { RefObject, useState } from "react";
import Image from "next/image";
import { Lang } from "../types";
import { OpenMenu, CloseMenu, Language } from "./icons";

function Header({
  isMobile,
  secondRef,
  thirdRef,
  lang,
  toggleLang,
  reducedMotion,
  refNavs,
}: {
  isMobile: boolean;
  secondRef: RefObject<HTMLDivElement>;
  thirdRef: RefObject<HTMLDivElement>;
  lang: Lang;
  reducedMotion: boolean | undefined;
  toggleLang: () => void;
  refNavs: boolean;
}): JSX.Element {
  const [menuOpen, setMenuOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function handleClick(ref: RefObject<HTMLDivElement>) {
    if (ref.current) {
      const { offsetTop } = ref.current;
      if (!reducedMotion) {
        window.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }
      if (reducedMotion) {
        window.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: "auto",
        });
      }
    }
  }

  if (!isMobile) {
    return (
      <header>
        <div className="container">
          <div className="logo">
            <a href="/" aria-label={lang.header.aria.home}>
              <Image
                src="/spvmLogo.svg"
                alt="Spårvägsmuseet Logo"
                width="115"
                height="55"
                unoptimized
              />
            </a>
          </div>
          <nav>
            {refNavs && (
              <button type="button" onClick={() => handleClick(secondRef)}>
                {lang.visit.sectionName}
              </button>
            )}
            {refNavs && (
              <button type="button" onClick={() => handleClick(thirdRef)}>
                {lang.about.sectionName}
              </button>
            )}
            <button type="button" onClick={() => toggleLang()}>
              <div className="icon-wrapper">
                <Language />
              </div>
              {lang.header.buttons.lang}
            </button>
          </nav>
        </div>
      </header>
    );
  }

  return (
    <header>
      <div className="container">
        {!menuOpen ? (
          <a href="/">
            <Image
              src="/spvmLogo.svg"
              alt="Spårvägsmuseet Logo"
              width="88"
              height="42"
              unoptimized
            />
          </a>
        ) : (
          <button type="button" onClick={() => toggleLang()}>
            <div className="icon-wrapper">
              <Language />
            </div>
            {lang.header.buttons.lang}
          </button>
        )}
        {!menuOpen && refNavs && (
          <button type="button" onClick={toggleMenu}>
            {lang.header.buttons.menu}
            <div className="icon-wrapper">
              <OpenMenu />
            </div>
          </button>
        )}
        {menuOpen && refNavs && (
          <button type="button" onClick={toggleMenu}>
            {lang.header.buttons.close}
            <div className="icon-wrapper">
              <CloseMenu />
            </div>
          </button>
        )}
        {!menuOpen && !refNavs && (
          <button type="button" onClick={() => toggleLang()}>
            <div className="icon-wrapper">
              <Language />
            </div>
            {lang.header.buttons.lang}
          </button>
        )}
      </div>
      <nav style={menuOpen ? { height: "100vh" } : { height: "0vh" }}>
        {menuOpen && (
          <div className="navs-container">
            <button
              type="button"
              onClick={() => {
                handleClick(secondRef);
                toggleMenu();
              }}
            >
              {lang.visit.sectionName}
            </button>
            <button
              type="button"
              onClick={() => {
                handleClick(thirdRef);
                toggleMenu();
              }}
            >
              {lang.about.sectionName}
            </button>
          </div>
        )}
      </nav>
    </header>
  );
}
export default Header;
