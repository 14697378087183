const langData = {
  sv: {
    header: {
      buttons: { lang: "English", menu: "Meny", close: "Stäng" },
      alt: { closeMenu: "Stäng meny", openMenu: "Öppna meny" },
      aria: { home: "Gå till startsidan" },
    },
    hero: {
      title: "Här döljer sig nya Spårvägsmuseet",
      alt: "Utanför museet",
      videoButton: {
        play: "Spela upp",
        pause: "Pausa",
      },
      aria: { playpause: "Play- och pauseknapp för video i bakgrunden" },
    },
    welcome: {
      title: "Snart kan du besöka oss igen",
      text: "Den 21 maj 2022 öppnar Spårvägsmuseet igen. På ett spännande och lekfullt sätt får du uppleva Stockholm och de fantastiska fordon som trafikerat staden i hundratals år.",
      media: { title: "Galleri med bilder" },
    },
    visit: {
      sectionName: "Hitta hit",
      title: "Välkommen till något nytt",
      text: "När Spårvägsmuseet öppnar igen i maj 2022 kommer det att ligga i nyrenoverade Gasverksområdet i Norra Djurgårdsstaden. Det imponerande huset från 1893 som ritades av Ferdinand Boberg är storslaget och värt ett besök i sig självt. I närheten av museet finns lekplatser, caféer och restauranger. Här ligger även Lill-Jansskogen med motionsslingor, ridning och bad.",
      alt: "Karta som visar museets plats.",
      address: "Adress: Gasverkstorget 1, 115 42 Stockholm",
      media: { title: "Karta som visar museets plats." },
    },
    about: {
      sectionName: "Om museet",
      title: "Från hästar till moderna tåg",
      text: "På museet finns ett myller av föremål och aktiviteter som berättar hur kollektivtrafiken påverkat livet för stockholmarna i över 150 år.",
      media: { title: "Galleri med bilder" },
    },
    infoBoxes: {
      discover: {
        title: "Se och göra",
        text: "På museet kommer du till exempel att kunna:",
        list: [
          "uppleva riktigt gamla fordon på nära håll",
          "se filmer och foton från Stockholm förr i tiden",
          "ta en tur i minitunnelbana",
          "testa hur det är att köra buss, pendeltåg eller tunnelbana",
          "handla i museets butik",
          "äta gott i caféet",
          "utmana en kompis i pendeltågsquiz.",
        ],
      },
      schoolInformation: {
        title: "Skolinformation",
        text: [
          "Innehållet i utställningarna på nya Spårvägsmuseet är gjorda för att passa grundskolans läroplan. Kontakta ",
          " för mer information. Inom kort kommer det vara möjligt att boka pedagogledd visning i museet. Mer information kommer. På Stockholmskällan finns material för årskurs F–3.",
        ],
        email: "sparvagsmuseet@sl.se",
        button: "Till Stockholmskällan",
      },
      ourCollections: {
        title: "Våra samlingar",
        text: [
          "I Spårvägsmuseets samlingar finns närmare 70 fordon, ",
          "20 000 ",
          "ritningar och ",
          "10 000 ",
          "andra föremål. Det finns även ",
          "500 000 ",
          "bilder varav ",
          "30 000 ",
          "är digitaliserade. De digitala samlingarna är under utveckling och kommer inom kort att kunna visas igen på museets webbplats. Till dess, följ gärna Spårvägsmuseets sociala medier där du också kan ta del av museets samlingar.",
        ],
        button: "Till våra samlingar",
      },
      alt: {
        caretDown: "Öppna sektion",
        caretUp: "Stäng sektion",
      },
    },
    footer: {
      contact: "Kontakt",
      follow: "Följ oss",
      accessibility: {
        title: "Om webbplatsen",
        link: "Tillgänglighetsredogörelse",
      },
      externalLinks: {
        title: "Externa länkar",
        links: [
          "SL:s webbplats",
          "Färdtjänstens webbplats",
          "Waxholmsbolagets webbplats",
          "SL:s konstwebb",
          "Region Stockholms webbplats",
        ],
      },
      bottom: { title: "Sidfot" },
    },
    gallery: {
      alt: {
        arrowLeft: "Se tidigare bild",
        arrowRight: "Se nästa bild",
        first: [
          "Höga fönster där solen strålar in och ner på golvet i nya Spårvägsmuseet.",
          "Fullskalig häst i trä på nya Spårvägsmuseet.",
          "Närbild på långsidan på en röd buss med logotypen SL och en pil med texten “Ingång”.",
          "Närbild på dörrhandtag på en äldre grön tunnelbanevagn.",
        ],
        second: [
          "Nattlig vy över Kungsgatan i Stockholm år 1964 med många neonskyltar som lyser upp. Gatan är tom förutom en trådbuss.",
          "Tom lokal i nya Spårvägsmuseet med ett stort äldre svartvitt foto som visar Fruängens tunnelbanestation.",
          "Vit skylt med en blå bokstav “J” hänger från taket i nya Spårvägsmuseet. Skylten är likadan som de vid pendeltågsstationer i Stockholm.",
          "Människor som går ombord på en blåvit spårvagn vid Stureplan år 1966. Bredvid spårvagnen står en rödvit buss.",
        ],
      },
    },
    mapbox: {
      marker: "Här finns vi!",
    },
    accessibility: {
      h1: "Tillgänglighet på sparvagsmuseet.se",
      paragraphs: [
        {
          text: "Trafikförvaltningen i Region Stockholm ansvarar för den här webbplatsen. Webbplatsen är i stort förenlig med lagen om tillgänglighet till digital offentlig service.",
        },
        {
          text: "Vi vill att så många som möjligt ska kunna använda www.sparvagsmuseet.se och de tjänster och funktioner som finns på webbplatsen. Vi är medvetna om att det finns en del brister i tillgängligheten för en del användare. Här berättar vi vilka brister vi känner till och när vi planerar att åtgärda dem.",
        },
        {
          h2: "Kontakta oss",
          text: "Hittar du problem eller brister som vi inte har listat kan du kontakta oss. Vi uppskattar alla synpunkter som kan göra webbplatsen mer tillgänglig. Du kan också kontakta oss om det är innehåll du behöver som inte är tillgängligt för dig.",
        },
        {
          text: "Vi  tar emot dina frågor och synpunkter dygnet runt. Kan de inte hjälpa dig direkt, ser de till att din fråga kommer till de som har ansvar för webbplatsen.",
        },
        {
          text: "Kontakta oss på ",
          email: "sparvagsmuseet@sl.se",
        },
        {
          h2: "Tillsyn",
          text: "Det är Myndigheten för digital förvaltning som har ansvaret för tillsyn att vi följer lagen om tillgänglighet till digital offentlig service. Om du inte är nöjd med hur vi uppfyller kraven på digital tillgänglighet kan du anmäla det till dem.",
        },
        {
          text: "Kontakta Myndigheten för digital förvaltning, DIGG (öppnar deras webbplats i nytt fönster)",
          url: "https://www.digg.se/tdosanmalan",
        },
        {
          h2: "Kända brister",
          text: "Här listar vi de brister vi känner till och när vi kommer att ha åtgärdat dem:",
          ul: {
            li: [
              "För kartan i sektionen ”Hitta hit” använder vi en extern funktion, ”Mapbox”,  för att kunna anpassa kartans utseende enligt Spårvägsmuseets visuella identitet. Denna funktion är delvis engelskspråkig (t ex kända platser). Det här är svårt att åtgärda men vi jobbar på att hitta en lösning för dessa under 2022",
            ],
          },
        },
        {
          h2: "Så har vi testat webbplatsen",
          text: "Vi har gjort en intern testning av tillgängligheten av webbplatsen under utvecklingsarbetet den 9 september 2021. Vi kommer också att uppdatera den här tillgänglighetsredogörelsen när förändringar och uppdateringar genomförs.",
        },
        {
          text: "Sidan uppdaterad 7 december 2021.",
        },
      ],
    },
  },
  en: {
    header: {
      buttons: { lang: "Svenska", menu: "Menu", close: "Close" },
      alt: { closeMenu: "Close menu", openMenu: "Open menu" },
      aria: { home: "Go to homepage" },
    },
    hero: {
      title: "A real treasure is hidden inside",
      alt: "Outside the museum",
      videoButton: {
        play: "Play",
        pause: "Pause",
      },
      aria: { playpause: "Play and pause button for background video" },
    },
    welcome: {
      title: "Soon you’ll be able to visit us again",
      text: "On the 21st of May 2022, Spårvägsmuseet will be opening again. With its exciting and playful exhibits, you’ll have the chance to experience Stockholm and the fantastic vehicles that have transported the people of Stockholm for hundreds of years.",
      media: { title: "Image slideshow" },
    },
    visit: {
      sectionName: "Visit",
      title: "Welcome to something new",
      text: "When Spårvägsmuseet reopens in May 2022, it will be located in the newly renovated Gasworks district in Norra Djurgårdsstaden. The impressive building from 1893, designed by Ferdinand Boberg, is magnificent and worth a visit in itself. There are also parks, restaurants and other activities close by.",
      alt: "Map of location.",
      address: "Address: Gasverkstorget 1, 115 42 Stockholm",
      media: { title: "Map of location" },
    },
    about: {
      sectionName: "About the museum",
      title: "From horses to modern trains",
      text: "The museum houses a huge number of objects and activities that illustrate how public transport has affected the lives of Stockholmers for more than 150 years.",
      media: { title: "Image slideshow" },
    },
    infoBoxes: {
      discover: {
        title: "Discover",
        text: "At the museum you will be able to:",
        list: [
          "experience vintage public transport vehicles up close",
          "watch films and see photos from the Stockholm of the past",
          "take a ride on a mini metro train",
          "find out what it’s like to drive a bus, commuter train or metro train",
          "visit the museum shop",
          "enjoy a Swedish “fika” in the cafe",
          "challenge a friend in the commuter train quiz.",
        ],
      },
      schoolInformation: {
        title: "Information for schools",
        text: [
          "The content of the exhibitions at Spårvägsmuseet has been designed to match the compulsory school curriculum. Please contact ",
          " for more information. From January 2022, it will be possible to book an educator-led tour of the museum. Materials for grade F–3 are available at Stockholmskällan.",
        ],
        button: "To Stockholmskällan",
        email: "sparvagsmuseet@sl.se",
      },
      ourCollections: {
        title: "Our collections",
        text: [
          "Spårvägsmuseet’s collections include almost 70 vehicles, ",
          "20,000 ",
          "drawings and ",
          "10,000 ",
          "other objects as well as ",
          "500,000 ",
          "photos, ",
          "30,000 ",
          "of which are digitized. The digital collections are under development and will soon be shown again on the museum's website. Until then, feel free to follow Spårvägsmuseet on social media where you can also take part in the museum's collections.",
        ],
        button: "To our collections",
      },

      alt: {
        caretDown: "Open section",
        caretUp: "Close section",
      },
    },
    footer: {
      contact: "Contact",
      follow: "Follow us",
      accessibility: { title: "About the website", link: "Accessibility" },
      externalLinks: {
        title: "External links",
        links: [
          "SL website",
          "Färdtjänsten website",
          "Waxholmsbolaget website",
          "SL konstwebb",
          "Region Stockholm website",
        ],
      },
      bottom: { title: "Footer" },
    },
    gallery: {
      alt: {
        arrowLeft: "See previous image",
        arrowRight: "See next image",
        first: [
          "Tall windows where the sun shines down the floor in the new Spårvägsmuseet.",
          "A wooden horse in full scale.",
          "Close up on the long side of a red bus with the SL logo and an arrow with the text ”Entrance”.",
          "Close up of door handle on an older green subway car.",
        ],
        second: [
          "Night view of Kungsgatan in Stockholm in 1964.  The street is empty except for a wire bus and a lot of neon signs that light up the street.",
          "Empty room inside the new Spårvägsmuseet with a large older black and white photo showing Fruängen's metro station.",
          "White sign with a blue letter ”J” hangs from the ceiling in the new Spårvägsmuseet. The sign is the same as those at commuter train stations in Stockholm.",
          "People boarding a blue and white tram at Stureplan in 1966. Next to the tram is a red and white bus.",
        ],
      },
    },
    mapbox: {
      marker: "Our location!",
    },
    accessibility: {
      h1: "Accessibility on the website sparvagsmuseet.se",
      paragraphs: [
        {
          text: "The Swedish Transport Administration in the Stockholm Region is responsible for this website. The website is largely compatible with the law on accessibility to digital public service.",
        },
        {
          text: "We want as many people as possible to be able to use sparvagsmuseet.se and the services and functions available on the website but we are aware about the fact that there are some shortcomings when it comes to availability for some users. Here we tell you what shortcomings we are aware of and when we plan to remedy them.",
        },
        {
          h2: "Contact us",
          text: "You can contact us if you find problems or deficiencies that we haven’t yet listed. We appreciate all forms of feedback that can help us make the site more accessible. You can also contact us if there is some content you need that is not available. You can also contact us if there is content you need that is not available to you.",
        },
        {
          text: "We are open to receive your questions and comments at any given time of the day. If you don’t receive the help you need directly, we will make sure your matter will reach those who are responsible for the website.",
        },
        {
          text: "Contact us at: ",
          email: "sparvagsmuseet@sl.se",
        },
        {
          h2: "Supervision",
          text: "It is the Agency for Digital Government that is responsible to supervise that we comply with the law on accessibility to digital public services. If you are not satisfied with how we meet the requirements for digital accessibility, you can report it to them.",
        },
        {
          text: "Contact the Agency for Digital Government DIGG (opens their website in a new window)",
          url: "https://www.digg.se/tdosanmalan",
        },
        {
          h2: "Known shortcomings",
          text: "Here we list the shortcomings we know: ",
          ul: {
            li: [
              "For the map in the section “Visit” we use an external function, “Mapbox”, to be able to adapt the map's appearance according to the Spårvägsmuseet visual identity. This function is partly in English. We are working on finding a solution to this in 2022.",
            ],
          },
        },
        {
          h2: "How we have tested the website",
          text: "We have conducted an internal test of the accessibility of the website during the development work on September 9, 2021. We will also update this accessibility report when changes and updates are implemented.",
        },
        {
          text: "Page updated December 7, 2021.",
        },
      ],
    },
  },
};

export default langData;
