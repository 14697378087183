import React from "react";

export function Facebook(): JSX.Element {
  return (
    <svg width="38" height="38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.48 0H1.52C.68 0 0 .68 0 1.52v34.96C0 37.32.68 38 1.52 38h34.96c.84 0 1.52-.68 1.52-1.52V1.52C38 .68 37.32 0 36.48 0Zm-4.389 11.091h-3.035c-2.38 0-2.84 1.13-2.84 2.793v3.663h5.68l-.74 5.733h-4.94V38h-5.924V23.285h-4.954v-5.738h4.954v-4.228c0-4.907 2.997-7.581 7.377-7.581 2.1 0 3.9.157 4.427.228v5.125h-.005Z" />
    </svg>
  );
}

export function Instagram(): JSX.Element {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 15.04a4.96 4.96 0 1 0 0 9.92 4.96 4.96 0 0 0 0-9.92Zm19.86-4.9a13.059 13.059 0 0 0-.84-4.52A8 8 0 0 0 34.38.98a13.06 13.06 0 0 0-4.52-.84C27.28 0 26.52 0 20 0s-7.28 0-9.86.14a13.06 13.06 0 0 0-4.52.84A8 8 0 0 0 .98 5.62a13.06 13.06 0 0 0-.84 4.52C0 12.72 0 13.48 0 20s0 7.28.14 9.86a13.72 13.72 0 0 0 .84 4.54 7.88 7.88 0 0 0 1.82 2.8 7.78 7.78 0 0 0 2.82 1.82c1.445.545 2.976.83 4.52.84 2.58.14 3.34.14 9.86.14s7.28 0 9.86-.14a13.059 13.059 0 0 0 4.52-.84 7.78 7.78 0 0 0 2.82-1.82 7.882 7.882 0 0 0 1.82-2.8 13.2 13.2 0 0 0 .84-4.54C40 27.28 40 26.52 40 20s0-7.28-.14-9.86Zm-5.08 16a11.461 11.461 0 0 1-.78 3.6A7.72 7.72 0 0 1 29.74 34a11.46 11.46 0 0 1-3.62.7H13.88a11.46 11.46 0 0 1-3.62-.7 7.021 7.021 0 0 1-2.62-1.72A7.02 7.02 0 0 1 6 29.74a10.98 10.98 0 0 1-.68-3.62V13.88A10.98 10.98 0 0 1 6 10.26a7.02 7.02 0 0 1 1.72-2.62A7.18 7.18 0 0 1 10.26 6a11.46 11.46 0 0 1 3.62-.7h12.24a11.46 11.46 0 0 1 3.62.7 7.02 7.02 0 0 1 2.62 1.72A7.02 7.02 0 0 1 34 10.26a11.46 11.46 0 0 1 .7 3.62V20c0 4.12.14 4.54.08 6.12v.02Zm-3.2-14.88a4.76 4.76 0 0 0-2.82-2.82A8 8 0 0 0 26 8H14a8 8 0 0 0-2.76.52 4.76 4.76 0 0 0-2.82 2.72A8.54 8.54 0 0 0 8 14v12a8.54 8.54 0 0 0 .52 2.76 4.76 4.76 0 0 0 2.82 2.82 8.54 8.54 0 0 0 2.66.52h12a8 8 0 0 0 2.76-.52 4.761 4.761 0 0 0 2.82-2.82A8 8 0 0 0 32.1 26V14a7.56 7.56 0 0 0-.52-2.76v.02ZM20 27.64A7.622 7.622 0 0 1 12.38 20 7.64 7.64 0 1 1 20 27.64Zm8-13.78a1.8 1.8 0 0 1 0-3.58 1.8 1.8 0 0 1 0 3.58Z" />
    </svg>
  );
}

export function ExternalLink(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 -6 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.4.6h-9.684v1.884h6.516l-7.158 7.158 1.284 1.284 7.158-7.158v6.516H23.4V.6Z" />
      <path d="M23.4 23.4H.6V.6h9v1.8H2.4v19.2h19.2v-7.2h1.8v9Z" />
    </svg>
  );
}

export function ArrowLeft(): JSX.Element {
  return (
    <svg width="15" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.29 25 0 12.5 12.29 0 15 2.756 5.42 12.5 15 22.244 12.29 25Z"
        fill="#FFCD05"
      />
    </svg>
  );
}

export function ArrowRight(): JSX.Element {
  return (
    <svg width="15" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.71 0 15 12.5 2.71 25 0 22.244 9.58 12.5 0 2.756 2.71 0Z"
        fill="#FFCD05"
      />
    </svg>
  );
}

export function OpenMenu(): JSX.Element {
  return (
    <svg viewBox="0 -2 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 4H0V0h30v4ZM25 15H0v-4h25v4ZM30 26H0v-4h30v4Z"
        fill="#FFCD05"
      />
    </svg>
  );
}

export function CloseMenu(): JSX.Element {
  return (
    <svg viewBox="-2 -3 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m1.417 1.713 20.337 20.26m-20.295-.204 20.337-20.26"
        stroke="#FFCD05"
        strokeWidth="4"
      />
    </svg>
  );
}

export function Language(): JSX.Element {
  return (
    <svg viewBox="0 -1 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3.25C8.51 3.25 3.25 8.51 3.25 15S8.51 26.75 15 26.75 26.75 21.49 26.75 15 21.49 3.25 15 3.25ZM.75 15C.75 7.13 7.13.75 15 .75S29.25 7.13 29.25 15 22.87 29.25 15 29.25.75 22.87.75 15Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.87 15.685H2v-2.5h24.87v2.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m14.522 1.112.937 1.06c6.446 7.298 6.446 18.097 0 25.395l-.937 1.06-.937-1.06c-6.447-7.298-6.447-18.097 0-25.395l.937-1.06Zm0 3.873a16.567 16.567 0 0 1 0 19.769 16.567 16.567 0 0 1 0-19.769Z"
      />
    </svg>
  );
}
