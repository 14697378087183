import React, { cloneElement, ReactElement, useRef } from "react";
import useWindowSize from "../hooks/useWindowSize";
import Header from "./header";
import Footer from "./footer";
import { Lang } from "../types";

function Layout({
  children,
  lang,
  toggleLang,
  reducedMotion,
  refNavs,
  handleRefNavs,
  isIE,
}: {
  children: ReactElement;
  lang: Lang;
  toggleLang: () => void;
  reducedMotion: boolean | undefined;
  refNavs: boolean;
  handleRefNavs: () => void;
  isIE: boolean;
}): JSX.Element {
  const { width } = useWindowSize();
  const isMobile = width < 992;

  const sectionRefs = useRef();

  // eslint-disable-next-line
  // @ts-ignore
  const secondRef = sectionRefs.current?.secondRef;
  // eslint-disable-next-line
  // @ts-ignore
  const thirdRef = sectionRefs.current?.thirdRef;

  return (
    <>
      <Header
        isMobile={isMobile}
        secondRef={secondRef}
        thirdRef={thirdRef}
        lang={lang}
        toggleLang={toggleLang}
        reducedMotion={reducedMotion}
        refNavs={refNavs}
      />
      <div className="main" role="main">
        {cloneElement(
          children,
          { ref: sectionRefs },
          { lang, reducedMotion, handleRefNavs, isIE }
        )}
      </div>
      <Footer lang={lang} />
    </>
  );
}

export default Layout;
