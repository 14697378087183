/* eslint-disable import/no-unresolved, spaced-comment */

import React, { useEffect, useState } from "react";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import smoothscroll from "smoothscroll-polyfill";
import Layout from "../components/layout";
import "../styles/global.scss";
import langData from "../langData";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const { sv, en } = langData;
  const [lang, setLang] = useState(sv);
  const [reducedMotion, setReducedMotion] = useState(false);
  const [refNavs, setRefNavs] = useState(true);
  const [isIE, setIsIE] = useState(true);

  const router = useRouter();

  function handleRefNavs(): void {
    if (router.pathname === "/tillganglighet") {
      setRefNavs(false);
    }
    if (router.pathname === "/" && !refNavs) {
      setRefNavs(true);
    }
  }

  useEffect(() => {
    let mediaQueryMatches = false;
    const mediaQuery = window.matchMedia("(prefers-reduced-motion)");
    mediaQueryMatches = mediaQuery?.matches;
    setReducedMotion(mediaQueryMatches);

    // const ua = window.navigator.userAgent;
    // const isIECheck = /Trident/.test(ua);
    // console.log("isIECheck", isIECheck)

    // @ts-ignore
    // eslint-disable-next-line
    setIsIE(/*@cc_on!@*/ false || !!document.documentMode);
    // setIsIE(true);

    if (isIE) setReducedMotion(true);
    if (reducedMotion === false) smoothscroll.polyfill();
  }, [isIE, reducedMotion]);

  function toggleLang() {
    if (lang === sv) {
      setLang(en);
    }
    if (lang === en) {
      setLang(sv);
    }
  }

  return (
    <Layout
      lang={lang}
      // eslint-disable-next-line
      toggleLang={toggleLang}
      reducedMotion={reducedMotion}
      refNavs={refNavs}
      // eslint-disable-next-line
      handleRefNavs={handleRefNavs}
      isIE={isIE}
    >
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;
